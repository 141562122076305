import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { Form, FieldGroup, Button, SearchInput } from '@lmig/lmds-react';

const FORM_ID = 'comparion-search';

const SiteSearch = ({
  type = 'page',
  cancelCallback,
  isLoading = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const router = useRouter() || '';
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    register,
    setFocus
  } = useForm({
    resolver: yupResolver(
      object().shape({
        keyword: string()
          .min(2, 'At least 2 characters are required')
          .required('Search keyword must be two characters or longer'),
      }),
    ),
  });

  const { ref: inputRef } = register('keyword');

  useEffect(() => {
    if (type !== 'page') {
      setFocus('keyword');
    }
  }, [type, setFocus]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const onLoadKeyword = urlParams.get('keyword');

    if (onLoadKeyword && type === 'page') {
      setSearchTerm(onLoadKeyword);
      setValue('keyword', onLoadKeyword);
    }
  }, [setValue, type]);

  const submitSearch = () => {
    const { keyword } = getValues();
    const query = {
      keyword,
    };

    if (router.pathname === '/search' && type !== 'page') {
      cancelCallback();
    }

    const params = new URLSearchParams(window?.location?.search);
    const passedQueryParams = new URLSearchParams(query);

    passedQueryParams.forEach((value, name) => {
      params.set(name, value);
    });

    router.push(
      {
        pathname: '/search',
        query,
      },
      `/search?${params.toString()}`,
      { shallow: true }
    );
  };

  const validationMessages = Object.entries(errors).map(([, { message }]) => ({ type: 'error', text: message }));

  return (
    <div role="search" className='siteSearch' data-testid={`siteSearch--${type}`} id={`siteSearch${type === 'page' ? '--page' : ''}`}>
      <Form id={FORM_ID} onSubmit={handleSubmit(submitSearch)}>
        <FieldGroup className='searchFieldGroup' isFull={type === 'page'} disableMargins="top" messages={validationMessages}>
          <div className="searchAndButton">
            <div className='inputField'>
              <SearchInput
                autoComplete="off"
                labelVisual='Search'
                data-testid="site-search-input"
                name="search"
                type="search"
                highlightType={errors.keyword ? 'error' : undefined}
                onChange={(e) => { setValue('keyword', e.target.value); setSearchTerm(e.target.value); }}
                innerRef={inputRef}
                value={searchTerm}
                onClearClicked={() => setSearchTerm('')}
                loading={isLoading}
              />
            </div>
            <Button className='searchButton' size="large" aria-label="search button" variant={type === 'hamburger' ? 'secondary' : 'primary'} type="submit" dynamicWidth>Search</Button>
            {type === 'header' && <Button className="searchButton" onClick={() => cancelCallback()} data-testid='cancel'>Cancel</Button>}
          </div>
        </FieldGroup>
      </Form>
      <style jsx global>{`@import './SiteSearch.scoped.scss';`}</style>
    </div>
  );
};

SiteSearch.propTypes = {
  type: PropTypes.oneOf(['page', 'header', 'hamburger']),
  cancelCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SiteSearch;

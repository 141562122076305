/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, IconButton, IconSearch, IconMenu, IconClose, Accordion, AccordionTab, Panel, PanelContent } from "@lmig/lmds-react";
import useEnvironmentalizeLink from "@lmig/dotcom-aspect-hooks/useEnvironmentalizeLink";
import WrapperConditional from '@lmig/dotcom-aspect-components/Utility/WrapperConditional';
import useMeasure from '@lmig/dotcom-aspect-hooks/useMeasure';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import LinkEnvironmentalize from '@lmig/dotcom-aspect-comparion-components/LinkEnvironmentalize';
import SiteSearch from "./Search/SiteSearch";

const Header = ({
  noNav = false
}) => {
  const [{ ref }, { width: windowWidth }] = useMeasure();
  const [insuranceOpen, setInsuranceOpen] = useState(false);
  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  // Header links
  const agentLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance-agent" });
  const quoteLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/quote" });
  const carLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/car-insurance" });
  const classicCarLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/classic-car-insurance" });
  const motorCycleLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/motorcycle-insurance" });
  const boatLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/boat-insurance" });
  const rvLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/rv-insurance" });
  const golfCartLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/golf-cart-insurance" });
  const atvLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/atv-insurance" });
  const homeLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/home-insurance" });
  const renterLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/renters-insurance" });
  const condoLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/condo-insurance" });
  const floodLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/flood-insurance" });
  const lifeLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/life-insurance" });
  const petLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/pet-insurance" });
  const umbrellaLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/umbrella-insurance" });
  const jewelryLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/jewelry-insurance" });
  const deviceLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance/device-insurance" });
  const aboutUsLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/about-us" });
  const careersLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/careers" });
  const faqLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/faq" });
  const insurancePartnersLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance-partners" });
  const partnersProgramLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/partnership-programs" });
  const insuranceAdviceLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/resources" });

  // image link
  const comparionLogo = useEnvironmentalizeLink({ domain: "COMPARION", link: "" });

  useEffect(() => {
    if (insuranceOpen) {
      const span = document.querySelector("#idk");
      span.classList.add("cover");
      span.addEventListener('click', () => {
        setInsuranceOpen(false);
        span.classList.remove("cover");
      });
      setAboutUsOpen(false);
    }
  }, [insuranceOpen]);

  useEffect(() => {
    if (aboutUsOpen) {
      const span = document.querySelector("#idk");
      span.classList.add("cover");
      span.addEventListener('click', () => {
        setAboutUsOpen(false);
        span.classList.remove("cover");
      });
      setInsuranceOpen(false);
    }
  }, [aboutUsOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      const span = document.querySelector("#idk");
      span.classList.add("sliderCover");
      span.addEventListener('click', () => {
        span.classList.remove("sliderCover");
      });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    ref.current = document.body;
  }, [ref]);

  useEffect(() => {
    if (windowWidth < 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setIsOpen(false);
      setIsMenuOpen(false);
      document.querySelector("#idk").classList.remove('sliderCover');
    }
  }, [windowWidth]);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.position = "relative";
      document.documentElement.style.overflow = "hidden";
      document.body.style.position = "relative";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.position = "";
      document.documentElement.style.overflow = "";
      document.body.style.position = "";
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  const onClickMegaMenu = () => {
    setIsOpen(true);
    setIsSearchOpen(false);
    setIsMenuOpen(true);
  };

  const onClickClose = () => {
    const span = document.querySelector("#idk");
    span.classList.remove("sliderCover");
    setIsOpen(false);
    setIsSearchOpen(false);
    setIsMenuOpen(false);
  };

  const renderWrapper = wrapperChildren => (
    <Panel
      position="right"
      isOpen={isOpen}
      id="SP-right"
      className="navPanel"
      onClose={onClickClose}
      sm="overlay"
      md="overlay"
      lg="overlay"
    >
      <PanelContent>
        {wrapperChildren}
      </PanelContent>
    </Panel>
  );

  const renderProductLinks = () => (
    <div className={isMobile ? "" : "dropDown"} id="productLinks">
      <div className="column">
        <div className="columnTitle">Vehicle insurance</div>
        <ul className="dropDownList">
          <li className="dropDownLinkProduct">
            <Link
              href={carLink}
              aria-label="car insurance"
              variant="navigational"
              caretPosition="none"
            >
              Everyday car
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={classicCarLink}
              aria-label="classic car insurance"
              variant="navigational"
              caretPosition="none"
            >
              Classic car
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={motorCycleLink}
              aria-label="motorcycle and e-bike insurance"
              variant="navigational"
              caretPosition="none"
            >
              Motorcycle & E-&#8288;bike
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={boatLink}
              aria-label="boat insurance"
              variant="navigational"
              caretPosition="none"
            >
              Boat & jet ski
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={rvLink}
              aria-label="recreation vehicle insurance"
              variant="navigational"
              caretPosition="none"
            >
              Recreational vehicle (RV)
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={golfCartLink}
              aria-label="golf cart insurance"
              variant="navigational"
              caretPosition="none"
            >
              LSV & Golf cart
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={atvLink}
              aria-label="atv and utv insurance"
              variant="navigational"
              caretPosition="none"
            >
              ATV & UTV
            </Link>
          </li>
        </ul>
      </div>
      <div className="column">
        <div className="columnTitle">Property insurance</div>
        <ul className="dropDownList">
          <li className="dropDownLinkProduct">
            <Link
              href={homeLink}
              aria-label="home insurance"
              variant="navigational"
              caretPosition="none"
            >
              Homeowners
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={renterLink}
              aria-label="renters insurance"
              variant="navigational"
              caretPosition="none"
            >
              Renters
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={condoLink}
              aria-label="condo insurance"
              variant="navigational"
              caretPosition="none"
            >
              Condo
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={floodLink}
              aria-label="flood insurance"
              variant="navigational"
              caretPosition="none"
            >
              Flood
            </Link>
          </li>
        </ul>
      </div>
      <div className="column">
        <div className="columnTitle">Other insurance</div>
        <ul className="dropDownList">
          <li className="dropDownLinkProduct">
            <Link
              href={lifeLink}
              aria-label="life insurance"
              variant="navigational"
              caretPosition="none"
            >
              Life
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={petLink}
              aria-label="pet insurance"
              variant="navigational"
              caretPosition="none"
            >
              Pet
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={umbrellaLink}
              aria-label="umbrella insurance"
              variant="navigational"
              caretPosition="none"
            >
              Umbrella
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={jewelryLink}
              aria-label="jewelry insurance"
              variant="navigational"
              caretPosition="none"
            >
              Jewelry
            </Link>
          </li>
          <li className="dropDownLinkProduct">
            <Link
              href={deviceLink}
              aria-label="device insurance"
              variant="navigational"
              caretPosition="none"
            >
              Devices
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderAboutUsLinks = () => (
    <div className={isMobile ? "listView" : "dropDownAbout"} id="aboutUsLinks">
      <div className="columnAbout">
        <div className="columnTitle">About Comparion</div>
        <ul className="dropDownList">
          <li className="dropDownLink">
            <Link
              href={partnersProgramLink}
              aria-label="partnership programs"
              variant="navigational"
              caretPosition="none"
            >
              Partnership programs
            </Link>
          </li>
          <li className="dropDownLink">
            <Link
              href={insurancePartnersLink}
              aria-label="insurance partners"
              variant="navigational"
              caretPosition="none"
            >
              Insurance partners
            </Link>
          </li>
          <li className="dropDownLink">
            <Link
              href={aboutUsLink}
              aria-label="about us"
              variant="navigational"
              caretPosition="none"
            >
              About us
            </Link>
          </li>
          <li className="dropDownLink">
            <Link
              href={careersLink}
              aria-label="careers"
              variant="navigational"
              caretPosition="none"
            >
              Careers
            </Link>
          </li>
        </ul>
      </div>
      <div className="columnAbout">
        <div className="columnTitle">Helpful resources</div>
        <ul className="dropDownList">
          <li className="dropDownLink">
            <Link
              href={faqLink}
              aria-label="frequently asked questions"
              variant="navigational"
              caretPosition="none"
            >
              FAQ
            </Link>
          </li>
          <li className="dropDownLink">
            <Link
              href={insuranceAdviceLink}
              aria-label="Insurance advice"
              variant="navigational"
              caretPosition="none"
            >
              Insurance advice
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <header id="comparionHeader">
      <div className="wrapper">
        <div className="imageWrapper">
          <a href={comparionLogo}>
            <Image imageDomain="COMPARION" preload>
              <img
                alt="Comparion Insurance Logo"
                src="/images/comparion_logo_teal.svg"
                height="36px"
                width="144px"
              />
            </Image>
          </a>
        </div>
        {isSearchOpen && !isMobile && <SiteSearch type="header" cancelCallback={() => setIsSearchOpen(false)} />}
        {!noNav && <>
          {isMobile && windowWidth > 0 && <div className="buttonWrapper">
            <IconButton
              onClick={onClickMegaMenu}
              background="transparent"
              style={{ display: `${!isMenuOpen ? "flex" : "none"}` }}
              className="navButton"
            >
              <span className="menuIcon"><IconMenu size="24" className="hamburgerMenu" /></span>
            </IconButton>
          </div>}
          <WrapperConditional condition={isMobile} wrapper={renderWrapper}>
            {isMobile && <>
              <div className="sliderTop">
                <div className="imageWrapperSlide">
                  <a href={comparionLogo}>
                    <Image imageDomain="COMPARION" preload>
                      <img
                        alt="Comparion Insurance Logo"
                        src="/images/comparion_logo_teal.svg"
                        height="36px"
                        width="144px"
                        className="sliderImage"
                      />
                    </Image>
                  </a>
                </div>
                <IconButton
                  onClick={onClickClose}
                  background="transparent"
                  style={{ display: `${isOpen ? "flex" : "none"}` }}
                >
                  <IconClose size="24" />
                </IconButton>
              </div>
              <div className="mobileSearch">
                <SiteSearch type="hamburger" cancelCallback={onClickClose} />
              </div>
            </>}
            {!isSearchOpen && <nav aria-label="Primary" className="mainNav">
              <ul className={isMobile ? "mobileNav" : "desktopNav"}>
                <li className="listItem">
                  <Link
                    href={agentLink}
                    aria-label="find an agent"
                    variant="navigational"
                    caretPosition="none"
                    onBackground="yellow"
                    className="navLink"
                  >
                    Find an agent
                  </Link>
                </li>
                <li className="listItem">
                  <LinkEnvironmentalize domain="COMPARION" attribution>
                    <Link
                      href={quoteLink}
                      aria-label="start a quote"
                      variant="navigational"
                      caretPosition="none"
                      onBackground="yellow"
                      className="navLink"
                    >
                      Start a quote
                    </Link>
                  </LinkEnvironmentalize>
                </li>
                {!isMobile && <><li className="listItem">
                  <Link
                    aria-label="shop insurance"
                    variant="navigational"
                    caretPosition="none"
                    onBackground="yellow"
                    className={insuranceOpen ? "dropDownLinkOpen" : "ctaLink"}
                    type='button'
                    onClick={() => setInsuranceOpen(true)}
                  >
                    Shop insurance
                  </Link>
                  {insuranceOpen && renderProductLinks()}
                </li>
                  <li className="listItem">
                    <Link
                      aria-label="About us"
                      variant="navigational"
                      caretPosition="none"
                      onBackground="yellow"
                      className={aboutUsOpen ? "dropDownLinkOpen" : "ctaLink"}
                      type='button'
                      onClick={() => setAboutUsOpen(true)}
                    >
                      About us
                    </Link>
                    {aboutUsOpen && renderAboutUsLinks()}
                  </li>
                  <li className="listItem">
                    <Link
                      aria-label="About us"
                      variant="navigational"
                      caretPosition="none"
                      onBackground="yellow"
                      type='button'
                      className="searchLabel"
                      onClick={() => {
                        setIsSearchOpen(true);
                        setAboutUsOpen(false);
                        setInsuranceOpen(false);
                      }}
                    >
                      <IconSearch size="16" className="searchIcon" />
                      Search
                    </Link>
                  </li></>}
              </ul>
              {isMobile &&
                <Accordion className="navAcc">
                  <AccordionTab labelVisual="Shop insurance">
                    {renderProductLinks()}
                  </AccordionTab>
                  <AccordionTab labelVisual="About us">
                    {renderAboutUsLinks()}
                  </AccordionTab>
                </Accordion>
              }
            </nav>}
          </WrapperConditional>
        </>}
      </div>
      <hr />
      <style global jsx>{`@import './Header.scoped.scss';
      
      `}</style>
    </header >
  );
};

Header.propTypes = {
  noNav: PropTypes.bool,
};

export default Header;

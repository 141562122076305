import React, { useContext } from "react";
import dynamic from 'next/dynamic';
import PropTypes from "prop-types";
import { ContextPage } from '@lmig/dotcom-aspect-components/ContextPage';
import Header from "./Header";
import AgentSessionCard from "./Agent/AgentSessionCard";
import CapSessionCard from "./Cap/CapSessionCard";
import Footer from "./Footer";

const BannerCat = dynamic(() => import('@lmig/dotcom-aspect-components/Utility/BannerCat'));

// janky svg color override on line 59, generated courtesy of https://codepen.io/sosuke/pen/Pjoqqp
const Layout = ({
  children,
  agentSession = true,
  noHeaderNav = false,
  capSession = true,
  isCapLanding = false,
  cap = {}
}) => {

  const { content: { meta: { catJsx = '', catDisabled = false, catIcon } = {} } = {} } = useContext(ContextPage);

  return (
    <>
      {catDisabled || !catJsx ? null : <BannerCat icon={catIcon}>{catJsx}</BannerCat>}
      <Header noNav={noHeaderNav} />
      {capSession && <CapSessionCard cap={cap} isCapLanding={isCapLanding} />}
      {children}
      {agentSession && <AgentSessionCard />}
      <Footer />
      <style global jsx>{`
      * {
        box-sizing: border-box;
      }
      #__next {
        width: 100%;
        height: auto;
      }
      html,
      body {
        padding: 0;
        margin: 0;
        color: #343741;
      }
      body {
        height: 100%;
        width: 100%;
        font-family: "Roboto", Arial, sans-serif;
        line-height: 1.75em;
        display: flex;
        flex: 1 auto;
        align-items: center;
        flex-direction: column;
      }
      .wrapper {
        width: 100%;
        margin: 0 auto;
        max-width: 80rem;
        height: auto;
      }
      a.lmig-Button:hover,
      a.lmig-Button:focus {
        color: #fff;
      }
      a,
      a:hover,
      a:focus {
        color: #343741;
      }
      a:hover > .lm-Icon-LinkCaretRight,
      a:focus > .lm-Icon-LinkCaretRight {
        filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(307deg) brightness(50%) contrast(107%);
      }
      @media (min-width: 64rem) {
        .spacer {
          height: 8rem;
        }
      }
      button,
      .lmig-Accordion,
      .lmig-Button,
      .lmig-SlidePanel,
      .lmig-Tabs-tabList-tab,
      .lmig-Tabs-tabPanel,
      .lm-Body,
      .lm-Disclaimer,
      .lm-Field-select,
      .lm-FieldGroup,
      .lm-FieldGroup-field--checkbox,
      .lm-FieldGroup-field--input,
      .lm-FieldGroup-field--select,
      .lm-FieldGroupMessages,
      .lm-Form,
      .lm-Heading,
      .lm-Link,
      .lm-Wayfinder {
        font-family: inherit;
      }
      @mixin dontUseMaxWidthButtonsOnMobile {
        display: block;
        min-width: 3rem;
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }
    `}</style>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.array.isRequired,
  agentSession: PropTypes.bool,
  capSession: PropTypes.bool,
  noHeaderNav: PropTypes.bool,
  isCapLanding: PropTypes.bool,
  cap: PropTypes.object,
};

export default Layout;
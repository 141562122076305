/* eslint-disable complexity */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardBase from '@lmig/dotcom-aspect-components/Utility/CardBase';
import { EntityContext } from '@lmig/dotcom-aspect-comparion-components/EntityContext';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import PhoneNumber from '@lmig/dotcom-aspect-components/Utility/PhoneNumber';
import { Link, IconSmartphone, IconEmail } from '@lmig/lmds-react';
import css from 'styled-jsx/css';
import LazyLoad from "../LazyLoad";
import Rating from '../Rating';

const AgentSessionCard = ({
  quoteForm = false
}) => {
  const { entities: { agent } } = useContext(EntityContext);

  useEffect(() => {
    if (agent.nNumber) {
      if (window?.utag_data) {
        window.utag_data.nNumber = agent.nNumber;
      }

      if (window?.galileoData) {
        window.galileoData.nNumber = agent.nNumber;
      }
    }
  }, [agent]);

  if (!agent.active) {
    return null;
  }

  const { className: headerClass, styles: headerStyles } = css.resolve`
  *{
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

  const { displayName, email, image, phones, aliasPath, firstName, preferredName, isRemote, offices, localOffice, rating } = agent;
  let imgSrc = 'comparion/entity/agent/blank-profile-photo.jpg';
  let imgAlt = 'Placeholder Image, Insurance Agent | Comparion';
  let agentName = preferredName || firstName;

  if (/\s/g.test(agentName)) {
    agentName = displayName;
  }

  if (image) {
    imgSrc = image.src || imgSrc;
    imgAlt = image.alt || imgAlt;
  }

  // eslint-disable-next-line no-nested-ternary
  const agentPhone = phones.primary ? phones.primary : (phones.cell ? phones.cell : null);
  const office = offices.find(({ officeType }) => officeType === "FIELD");
  const remoteAgentWithLocalOffice = isRemote && localOffice;
  const isRemoteOfficeValid = remoteAgentWithLocalOffice && localOffice.city && localOffice.state.name && localOffice.state.code;
  const officeAddress = isRemoteOfficeValid ? localOffice : office.address;
  const languages = agent.languages.map(language => language.language);

  return (
    <LazyLoad>
      <div className={quoteForm ? "agentQuoteCard" : "agentSessionCard"} data-testid="agentSessionCard">
        <CardBase shadow padding shadowStyle="floating" style={quoteForm ? { height: "100%", borderRadius: "3px" } : { height: "100%" }}>
          <div className="yourAgent">{quoteForm ? "Your Comparion agent" : "Agent assigned to you"}</div>
          <div className="agentContainer">
            <div className="agentImage">
              <Image type="circle" imageDomain="IMAGE" deferLoad={false}>
                <img src={`${imgSrc}?height=120&width=120`} alt={imgAlt} width="7.5rem" height="7.5rem" />
              </Image>
            </div>
            <div className="contact">
              <div className="nameWrapper">
                <Link variant="standalone" caretPosition="right" style={{ fontWeight: "bold", fontSize: "1.125rem" }} href={`/${aliasPath}`}>
                  {displayName}
                </Link>
              </div>
              {agentPhone &&
                <div className="contactPhone">
                  <span>
                    <IconSmartphone size="16" />
                  </span>
                  <PhoneNumber tel={agentPhone.number}>
                    <Link
                      variant="standalone"
                      caretPosition="none"
                      aria-label="Contact us"
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </PhoneNumber>
                </div>
              }
              <div className="email">
                <IconEmail size="16" />
                <Link variant="standalone" caretPosition="none" href={`mailto:${email}`} style={{ marginLeft: "0.5rem" }}>Email {agentName}</Link>
              </div>
            </div>
            <div className="location">
              <div className={headerClass}>{quoteForm ? `Location |\xa0` : "Location"}</div>
              {`${officeAddress.city}, ${officeAddress.state.code}`}
            </div>
            {!!languages && languages.length > 1 &&
              <div className="languages">
                <div className={headerClass}>{quoteForm ? `Languages |\xa0` : "Languages"}
                  {quoteForm && <div className='lanuagesInline'>{languages.join(", ")}</div>}
                </div>
                {!quoteForm && languages.join(", ")}
              </div>
            }
            {quoteForm && rating && <div className='reviews'>
              <div className={headerClass}>{`Reviews\xa0`}</div>
              <Rating rating={rating} type="quoteCard" />
            </div>}
          </div>
        </CardBase>
      </div>
      {quoteForm && <div className='findAgent'><Link href="/insurance-agent">Find a different agent.</Link></div>}
      {headerStyles}
      <style jsx>{`
          @import './global-styles/tokens';
          .agentQuoteCard{
            max-width: 17.8125rem;
            width: unset;
            @media screen and (min-width: $lm-breakpoint-sm-min) {
              width: 19.5rem;
              max-width: unset;
            }
            @media screen and (min-width: $lm-breakpoint-md-min) {
              width: 23.25rem;
              max-width: unset;
            }
            .yourAgent{
              display: block;
              font-size: 1.125rem;
              font-weight: bold;
              border-left: 0.25rem solid #99E5EA;
              padding-left: 0.75rem;
              margin: -0.3rem 0 1rem -1rem;
              text-align: left;

              @media screen and (min-width: $lm-breakpoint-sm-min) {
                font-size: 1.25rem;
                margin: 0 0 1rem 0;
              }
              @media screen and (min-width: $lm-breakpoint-md-min) {
                font-size: 1.25rem;
                margin: 1rem 0 1rem 1rem;
              }
            }
            .agentContainer{
              display: flex;
              position: relative;
              align-items: center;
              justify-content: space-around;
              flex-direction: column;
              text-align: center;
              padding: 0 0.5rem 0 0.5rem;

              @media screen and (min-width: $lm-breakpoint-sm-min) {
                padding: 0.5rem;
              }

              .agentImage{
                border: 0.5rem solid #e6e6e6;
                border-radius: 50%;
                min-height: 7.5rem;
                min-width: 7.5rem;
                max-height: 7.5rem;
                max-width: 7.5rem;
                margin: unset;

                img {
                  width: 6.5rem;
                  height: 6.5rem;
                  object-fit: cover;
                }
              }
              .contact{
                display: flex;
                flex-direction: column;
                min-width: 9rem;
                margin-top: 1rem;
                text-align: center;
                margin-right: unset;

                .contactPhone,
                .email {
                  margin-top: 0.5rem;
                }
              }
              .location{
                display: flex;
                margin-right: unset;
                margin-top: 0.5rem;
                max-width: 20rem;
                flex-flow: row;
              }
              .languages{
                display: flex;
                max-width: 20rem;
                margin-top: 0.5rem
              }
              .reviews{
                display: flex;
                margin: 0.5rem 0 -0.5rem 0;
              }
              .lanuagesInline{
                display: inline;
                font-weight: 300;
                white-space: wrap;
              }
            }
          }
          .agentSessionCard {
            display: flex;
            width: 100%;
            max-width: 72rem;
            margin: 0 auto;
            justify-content: space-around;
            margin-bottom: 2.5rem;
          }
          .agentContainer {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            text-align: center;
            
            @media screen and (min-width: 37.5rem) {
              align-items: flex-start;
              flex-direction: row;
              justify-content: space-between;
              text-align: left;
            }
          }
          .yourAgent {
            display: block;
            font-size: 1.125rem;
            font-weight: bold;
            border-left: 0.25rem solid #99E5EA;
            padding-left: 0.75rem;
            margin-bottom: 1rem;

            @media screen and (min-width: 37.5rem) {
              margin-bottom: 0.5rem;
            }
          }
          .agentImage {
            border: 0.5rem solid #e6e6e6;
            border-radius: 50%;
            min-height: 7.5rem;
            min-width: 7.5rem;
            max-height: 7.5rem;
            max-width: 7.5rem;

            @media screen and (min-width: 37.5rem) {
              margin-right: 2rem;
            }
            img {
              width: 6.5rem;
              height: 6.5rem;
              object-fit: cover;
            }
          }
          .contact {
            display: flex;
            flex-direction: column;
            min-width: 9rem;
            margin-top: 1rem;
            text-align: center;

            .contactPhone,
            .email {
              margin-top: 0.5rem;
            }

            @media screen and (min-width: 37.5rem) {
              text-align: left;
              margin-top: 1rem;
              margin-right: 2rem;
              margin-bottom: 0;
            }
          }
          .location {
            @media screen and (min-width: 37.5rem) {
              margin-right: 2rem;
            }
          }
          .languages,
          .location {
            margin-top: 1rem;
            min-width: 6rem;
            max-width: 11rem;
            display: flex;
            justify-content: space-around;
            flex-flow: column;
          }
          .findAgent{
            text-align: center;
            margin: 1.5rem 0 3rem 0;
          }
        `}</style>
    </LazyLoad>
  );
};

AgentSessionCard.propTypes = {
  quoteForm: PropTypes.bool,
};

export default AgentSessionCard;